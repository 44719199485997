/* Styling for the Projects Section */

.project {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.project img {
  width: 200px;
  height: 200px;
  border-radius: 8px;
  margin-bottom: 15px;
}

.project h3 {
  font-size: 1.8em;
  color: #004080;
  margin-bottom: 10px;
}

.project p {
  line-height: 1.6;
  text-align: center;
  max-width: 600px;
}

.project a {
  display: block;
  text-align: center;
  padding: 10px 20px;
  margin-top: 10px;
  background-color: #004080;
  color: white;
  border-radius: 5px;
  text-decoration: none;
}

.project a:hover {
  background-color: #45a049;
}
