/* Styling for the Experience & Skills Section */

.experience-item {
  margin-bottom: 20px;
}

.experience-item h3 {
  font-size: 1.5em;
  color: #004080;
  margin-bottom: 10px;
}

.experience-item ul {
  padding: 0;
  list-style: disc;
  margin-left: 20px;
}

.experience-item li {
  margin-bottom: 5px;
}

.skills ul {
  padding: 0;
  list-style: disc;
  margin-left: 20px;
}

.skills li {
  margin-bottom: 10px;
}

.skills li strong {
  font-weight: bold;
  margin-right: 5px;
}
