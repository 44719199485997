/* src/index.css
   Purpose: Contains blog post listings, hero section, footer, responsive design, contact form, engagement table, and blog post content.
*/

/* GENERAL STYLING */
body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

body.light-mode {
  color: #333; /* Dark text for light background */
  background-color: #f9f9f9; /* Light background */
}

body.dark-mode {
  color: #e0e0e0; /* Light grey text for contrast on dark background */
  background-color: #1e1e1e; /* Dark background */
}

a {
  color: #4caf50; /* Your chosen color for links */
  text-decoration: none;
  transition: color 0.3s ease; /* Smooth color transition for hover effect */
}

a:hover {
  text-decoration: underline;
}

/* NAVIGATION BAR */
nav {
  background-color: #004080; /* Primary color */
  color: #fff; /* White text for contrast */
  padding: 15px 20px;
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav a {
  color: #fff; /* White text for contrast */
  margin: 0 10px;
  text-decoration: none;
  font-weight: 600; /* Slightly bolder font weight for better visual hierarchy */
  transition: color 0.3s ease; /* Smooth color transition for hover effect */
}

nav a:hover {
  color: #ffcc00; /* Highlight color on hover */
}

/* HERO SECTION */
.hero {
  text-align: center;
  padding: 50px 20px;
}

.hero h1 {
  font-size: 2.5em;
  color: #4caf50; /* Your chosen color for headings */
}

.hero p {
  font-size: 1.2em;
  color: #ccc; /* Light grey for body text */
}

.hero button {
  background-color: #4caf50; /* Your chosen color for buttons */
  color: #fff; /* White text for contrast */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth background color transition for hover effect */
}

.hero button:hover {
  background-color: #45a049; /* Slightly darker shade of the button color on hover */
}

/* BLOG POST LISTINGS */
.blog-posts {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.blog-post {
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #333; /* Subtle border for separation */
}

.blog-post img {
  width: 100px;
  height: 100px;
  margin-right: 20px;
  border-radius: 8px;
}

.blog-post h2 {
  font-size: 1.5em;
  color: #4caf50; /* Your chosen color for headings */
}

.blog-post p {
  margin-top: 10px;
  color: #353535; /* Light grey for body text */
  line-height: 1.5; /* Adjust line height for better readability */
}

/* FOOTER */
footer {
  background-color: #004080; /* Primary color */
  color: #fff; /* White text for contrast */
  padding: 30px 0; /* Increased padding for visual breathing room */
  text-align: center;
}

footer a {
  color: #ffcc00; /* Highlight color for links */
  margin: 0 10px;
  text-decoration: none;
  transition: color 0.3s ease; /* Smooth color transition for hover effect */
}

footer a:hover {
  color: #4caf50; /* Your chosen color for links */
}

footer .social-icons {
  margin-top: 20px;
}

footer .social-icons img {
  width: 32px; /* Larger icons for better visibility */
  height: 32px;
  margin: 0 10px;
  transition: opacity 0.3s ease; /* Smooth opacity transition for hover effect */
}

footer .social-icons img:hover {
  opacity: 0.7;
}

/* RESPONSIVE DESIGN */
@media (max-width: 600px) {
  .blog-post {
    flex-direction: column; /* Stack elements vertically on smaller screens */
  }

  .blog-post img {
    margin-right: 0; /* Remove margin on smaller screens */
    margin-bottom: 10px; /* Add bottom margin for spacing */
  }

  .social-icons img {
    width: 24px; /* Slightly smaller icons for smaller screens */
    height: 24px;
  }
}

/* CONTACT FORM */
.contact-form {
  max-width: 500px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: var(--background-color);
  color: var(--font-color);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-form button {
  background-color: #4caf50;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #45a049;
}

/* ENGAGEMENT TABLE */
table {
  width: 100%;
  border-collapse: collapse;
  margin: 2rem 0;
}

table,
th,
td {
  border: 1px solid #ddd;
}

th,
td {
  padding: 12px;
  text-align: left;
}

th {
  background-color: #004080;
  color: white;
}

tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

tbody tr:hover {
  background-color: #ddd;
}

body.dark-mode th {
  background-color: #333;
}

body.dark-mode tbody tr:nth-child(even) {
  background-color: #2c2c2c;
}

body.dark-mode tbody tr:hover {
  background-color: #444;
}

/* BLOG POST CONTENT */
.blog-post-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.blog-post {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 40px;
}

.blog-post-title {
  font-size: 2.5em;
  color: var(--font-color);
  margin-bottom: 10px;
}

.blog-post-date {
  font-size: 1em;
  color: var(--secondary-font-color);
  margin-bottom: 20px;
}

.blog-post-content {
  line-height: 1.6;
  color: var(--font-color);
  margin-bottom: 20px;
}

.social-share {
  display: flex;
  gap: 10px;
  margin-bottom: 40px;
}

.blog-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  border-top: 1px solid var(--border-color);
  padding-top: 20px;
}

.blog-navigation a {
  color: var(--link-color);
  text-decoration: none;
  font-size: 1em;
  transition: color 0.3s;
}

.blog-navigation a:hover {
  color: var(--link-hover-color);
}

body.light-mode .blog-post-content {
  color: #333;
}

body.dark-mode .blog-post-content {
  color: #e0e0e0;
}
