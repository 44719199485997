/* Styling for the Hire Me page */

.hire-me-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.section-title {
  font-size: 2em;
  color: #004080;
  margin-bottom: 20px;
}

.engagement-packages {
  margin-top: 30px;
}

.package-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
}

.package-header {
  background-color: #004080;
  color: white;
  text-align: left;
}

.package-column,
.price-column,
.details-column {
  padding: 15px;
  border: 1px solid #ddd;
}

.package-row:nth-child(even) {
  background-color: #f2f2f2;
}

.package-row:nth-child(odd) {
  background-color: #ffffff;
}

.package-column {
  width: 20%;
  font-weight: bold;
}

.price-column {
  width: 20%;
  font-weight: bold;
  color: #004080;
}

.details-column {
  width: 60%;
}

.contact-form-section {
  margin-top: 40px;
}

.contact-form {
  max-width: 500px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: var(--background-color);
  color: var(--font-color);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-form .mb3 {
  margin-bottom: 1rem;
}

.contact-form label {
  display: block;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 0.5rem;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: var(--background-color);
  color: var(--font-color);
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #4caf50;
  outline: none;
}

.contact-form button {
  background-color: #4caf50;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #45a049;
}
