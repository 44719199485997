/* src/styles.css
   Purpose: Contains general styling, navigation bar, sidebar, content area, switch buttons, footer, and additional styling.
*/

/* GENERAL STYLING */
body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

:root {
  --font-color: #333;
  --background-color: #f9f9f9;
  --link-color: #4caf50;
  --link-hover-color: #ffcc00;
  --border-color: #333;
  --secondary-font-color: #777;
}

body.light-mode {
  --font-color: #333;
  --background-color: #f9f9f9;
  --link-color: #4caf50;
  --link-hover-color: #ffcc00;
  --border-color: #333;
  --secondary-font-color: #777;
}

body.dark-mode {
  --font-color: #e0e0e0;
  --background-color: #1e1e1e;
  --link-color: #4caf50;
  --link-hover-color: #ffcc00;
  --border-color: #555;
  --secondary-font-color: #aaa;
  color: var(--font-color);
  background-color: var(--background-color);
}

a {
  color: var(--link-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  text-decoration: underline;
}

/* APP CONTAINER */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-container .content {
  flex: 1;
}

/* NAVIGATION BAR */
.header-top {
  background-color: #004080;
  color: #fff;
  padding: 15px 20px;
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-top .nav {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.header-top .nav-link {
  color: #fff;
  margin: 0 15px;
  font-weight: 600;
  text-transform: uppercase;
  transition: color 0.3s ease;
}

.header-top .nav-link:hover {
  color: var(--link-hover-color);
}

/* SIDEBAR */
.sidebar {
  background-color: #004080;
  color: #fff;
  padding: 15px 20px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 220px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  z-index: 1000;
}

.sidebar .profile {
  text-align: center;
}

.sidebar .profile-pic {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.sidebar .profile h1 {
  margin: 10px 0;
  font-size: 1.5em;
}

.sidebar .profile p {
  font-size: 0.9em;
}

.sidebar .nav {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
}

.sidebar .nav-link {
  color: #fff;
  margin: 10px 0;
  font-weight: 600;
  text-transform: uppercase;
  transition: color 0.3s ease;
}

.sidebar .nav-link:hover {
  color: var(--link-hover-color);
}

/* CONTENT AREA */
.content {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.app-container.side .content {
  margin-left: 340px;
}

/* SWITCH BUTTONS */
.toggle-nav,
.toggle-dark-mode {
  position: fixed;
  bottom: 20px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  z-index: 1100;
}

.toggle-nav {
  right: 20px;
}

.toggle-dark-mode {
  left: 20px;
}

.toggle-nav:hover,
.toggle-dark-mode:hover {
  background-color: #45a049;
}

/* FOOTER */
.footer {
  background-color: #004080;
  color: #fff;
  padding: 20px 0;
  text-align: center;
  flex-shrink: 0;
}

.footer a {
  color: var(--link-hover-color);
  margin: 0 10px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer a:hover {
  color: var(--link-color);
}

.footer .social-icons {
  margin-top: 20px;
}

.footer .social-icons .social-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0 10px;
  transition: opacity 0.3s ease;
}

.footer .social-icons .social-icon:hover {
  opacity: 0.7;
}

/* RESPONSIVE DESIGN */
@media (max-width: 600px) {
  .blog-post {
    flex-direction: column;
  }

  .blog-post img {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .social-icons .social-icon {
    width: 24px;
    height: 24px;
  }
}

/* ADDITIONAL STYLING */
.app-container.side .sidebar {
  display: block;
}

.app-container.side .header-top {
  display: none;
}

.app-container.top .sidebar {
  display: none;
}

.app-container.top .header-top {
  display: flex;
}

/* Contact Form */
.contact-form-container {
  padding: 3rem;
}

.contact-form {
  max-width: 600px;
  margin: 0 auto;
}

.contact-form .mb3 {
  margin-bottom: 1rem;
}

.contact-form label {
  display: block;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 0.5rem;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: var(--background-color);
  color: var(--font-color);
  margin-bottom: 1rem;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #4caf50;
  outline: none;
}

.contact-form button {
  display: block;
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 5px;
  background-color: #4caf50;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: #45a049;
}
