.what-is-fractional-cto-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: var(--background-color); /* Use your background color */
  color: var(--font-color); /* Use your font color */
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

.what-is-fractional-cto-container h2 {
  font-size: 2em;
  color: #004080; /* Use your primary color */
  margin-bottom: 1rem;
}

.what-is-fractional-cto-container h3 {
  font-size: 1.5em;
  color: #4caf50; /* Use your accent color */
  margin-bottom: 0.5rem;
}

.what-is-fractional-cto-container p {
  margin-bottom: 1rem;
  line-height: 1.6;
}

.what-is-fractional-cto-container ul {
  list-style: disc;
  margin-left: 20px;
}

.what-is-fractional-cto-container li {
  margin-bottom: 0.5rem;
}
